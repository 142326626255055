.container {
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.switchContainer {
    width: 36px;
    height: 20px;
    background-color: var(--border);
    border-radius: 50px;
    position: relative;
    transition: background-color 0.3s ease-in-out;
}

.switch {
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 2px;
    transform: translateY(-50%);
    transition: left 0.3s ease-in-out;
}

.checked {
    background-color: var(--primary);
}

.checked .switch {
    left: 18px;
}

.labelContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.label {
    font-size: 14px;
    font-weight: 500;
    color: var(--text);
    ;
}